import { useState, useEffect, useMemo, useRef } from 'react'

import { MosaicIcon, ArrowIcon, ToggleIcon, AutoPlayOff, FullScreen, StretchVideo, ExitStretchVideo } from '../../assets/images'

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'

import Pagination from '../../components/Pagination/Pagination'

import { GetDevicesListData, Getdeviceslistdata } from '../../services/configurationServices'

import { THIRTY_MINUTES } from '../../navigation/CONSTANTS'
import { mixpanel } from '../../utils/MixPanelUtil'
import { CLOUD_STREAMING, IS_WEBRTC, MOSAIC_REFRESH_TIMER } from '../../config'
import Matrix from './Matrix'
import { isNotNullOrEmpty, parseSCSEntitiesToObect } from '../../utils/utils'
import DDLSingleSelect from '../../components/DDL/DDLSingleSelect'
import { useDispatch, useSelector } from 'react-redux'
import { filterActions } from '../../redux/features/shared/filter'
import { fetchSavedFiltersList } from '../../services/filterService'
import { SuccessToast } from '../../CustomHooks/SuccessToast';

const CAMERA = "cameras";
const SAVEDFILTER = "Saved Filter";


export default function MosaicContainer() {
    //#region useState start
    const [mosaicOption, setMosaicOption] = useState(1) // option value
    const [camerasUI, setCamerasUI] = useState(null)
    const [cameraDeviceList, setCameraDeviceList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(4) // page size matched with option value 1=>4(2X2), etc
    const [isMenuVisible, setIsMenuVisible] = useState(true)
    const [isAutoPlay, setIsAutoPlay] = useState(false)
    const [intervalId, setIntervalId] = useState(null);
    const [totalPageCount, setTotalPageCount] = useState(null)
    const [intervalTime, setIntervalTime] = useState(30);
    const [time, setTime] = useState(Date.now());
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [oneKey, setOneKey] = useState(0);
    const [selected, setSelected] = useState([]);
    const [stretchVideo, setStretchVideo] = useState(false)
    //#endregion useState end

    //#region Redux states and dispatcher
    const dispatch = useDispatch()
    const filter = useSelector(state => state?.filter);
    const { savedFilters, customer, site, filterName } = filter;
    //#endregion

    //#region useRef start
    const totalPageCountRef = useRef(null);
    const playerRef = useRef(null);
    //#endregion useRef end

    //#region useEffect start
    useEffect(() => {
        let filterParams = urlQueryParams();
        const { clientIds, siteIds, interfaceSubTypeIds, interfaceIds, deviceIds } = filterParams;

        //Fetch saved filters list
        if (!isNotNullOrEmpty(savedFilters?.options)) {
            dispatch(fetchSavedFiltersList(CAMERA))
        }

        (async () => {
            getdeviceslistdata(clientIds, siteIds, interfaceSubTypeIds, interfaceIds, deviceIds) //device master list
        })();

    }, [savedFilters?.selected])

    useEffect(() => {
        const totalPages = Math.ceil(totalNoOfCameras / pageSize)
        setTotalPageCount(totalPages)
        totalPageCountRef.current = totalPages
    }, [cameraDeviceList, mosaicOption])

    useEffect(() => {
        if (isAutoPlay) {
            let autoPlayInterval;
            clearInterval(autoPlayInterval)
            autoPlayInterval = setInterval(autoPlayStart, intervalTime * 1000);
            setIntervalId(autoPlayInterval);
            return () => clearInterval(autoPlayInterval)
        }
    }, [isAutoPlay, intervalTime])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(Date.now())
        }, THIRTY_MINUTES); // set the interval to 30 min
        return () => clearInterval(intervalId); // clear the interval on unmount
    }, []);

    useEffect(() => {
        if (IS_WEBRTC == 0) {
            if (cameraDeviceList.length > 0) {
                const firstPageIndex = (currentPage - 1) * pageSize;
                const lastPageIndex = firstPageIndex + pageSize;
                let firstList = cameraDeviceList?.slice(firstPageIndex, lastPageIndex);
                let firstIndex = lastPageIndex;
                let secondIndex = lastPageIndex + pageSize;
                let secondList = cameraDeviceList?.slice(firstIndex, secondIndex);
                let maxCount = Math.ceil(cameraDeviceList.length / pageSize);
                if (maxCount == currentPage) {
                    let firstArr = cameraDeviceList?.slice(0, pageSize);
                    for (let i = 0; i < firstArr.length; i++) {
                        if (CLOUD_STREAMING == '0') {
                            let baseUrl = "http://localhost:3000"
                            let tempbaseUrl = "http://localhost:3000";
                            let rtspurl = firstArr[i].RTSPURL
                            //get cam guid
                            let camguid = firstArr[i].StreamName
                            //do post req to base url
                            let actbaseUrl = baseUrl + "/start"
                            let IsTrancoding = false
                            //   if(secondList[i].istranscdng==1)
                            if (firstArr[i].isTranscoded == 1) {
                                IsTrancoding = true
                            }
                            fetch(actbaseUrl, {
                                method: 'POST',
                                body: JSON.stringify({
                                    uri: rtspurl,
                                    alias: camguid,
                                    isencode: IsTrancoding
                                }),
                                headers: {
                                    'Content-type': 'application/json; charset=UTF-8',
                                },
                            }).then((res) => res.json())
                                .then((data) => {
                                    baseUrl = baseUrl + data['uri']
                                    //   setPlayUrl(baseUrl)
                                    //item.
                                })
                                .catch((err) => {
                                });
                        }
                    }
                }
                for (let i = 0; i < secondList.length; i++) {
                    if (CLOUD_STREAMING == '0') {
                        let baseUrl = "http://localhost:3000"
                        let tempbaseUrl = "http://localhost:3000";
                        let rtspurl = secondList[i].RTSPURL
                        //get cam guid
                        let camguid = secondList[i].StreamName
                        //do post req to base url
                        let actbaseUrl = baseUrl + "/start"
                        let IsTrancoding = false
                        //   if(secondList[i].istranscdng==1)
                        if (secondList[i].isTranscoded == 1) {
                            IsTrancoding = true
                        }
                        fetch(actbaseUrl, {
                            method: 'POST',
                            body: JSON.stringify({
                                uri: rtspurl,
                                alias: camguid,
                                isencode: IsTrancoding
                            }),
                            headers: {
                                'Content-type': 'application/json; charset=UTF-8',
                            },
                        }).then((res) => res.json())
                            .then((data) => {
                                baseUrl = baseUrl + data['uri']
                                //   setPlayUrl(baseUrl)
                                //item.
                            })
                            .catch((err) => {
                            });
                    }
                }
            }
        }
    }, [currentPage, pageSize, cameraDeviceList])

    // This will refresh the entire page to the initial state
    useEffect(() => {
        let timer = setInterval(() => {
            // window.location.reload(false);
            let temp = oneKey + 1;
            console.log("Refresh", temp);
            setOneKey(temp)
        }, MOSAIC_REFRESH_TIMER);

        return () => {
            clearInterval(timer);
        }
    }, [oneKey])

    //#endregion useEffect end

    const options = [
        { value: "30", label: "30 sec" },
        { value: "35", label: "35 sec" },
        { value: "40", label: "40 sec" },
        { value: "45", label: "45 sec" },
        { value: "50", label: "50 sec" },
        { value: "55", label: "55 sec" },
        { value: "60", label: "60 sec" }
    ];

    //#region functions start

    const urlQueryParams = () => {
        const params = new URLSearchParams(window.location.search);
        const props = {};
        for (const [key, value] of params.entries()) {
            props[key] = value;
        }
        let filterData = null;
        if (props) {
            filterData = parseSCSEntitiesToObect(props?.filterText);
        }

        return filterData;
    }

    const getdeviceslistdata = async (clientIds, siteIds, interfaceSubTypeIds, interfaceIds, deviceIds) => {
        try {
            // Getdeviceslistdata().then((resp) => {
            //     let finalCamList = resp;
            //     setCameraDeviceList(finalCamList)
            //     setCurrentPage(1)
            // })
            await GetDevicesListData(clientIds, siteIds, interfaceSubTypeIds, interfaceIds, deviceIds).then(data => {
                // let finalCamList = resp;
                setCameraDeviceList(data)
                setCurrentPage(1)
            })
        } catch (error) {
            console.error(error)
        }
    }

    function onArrowIconClick() {
        if (isMenuVisible == true) {
            setIsMenuVisible(false)
        }
        else {
            setIsMenuVisible(true)
        }
    }

    function OnAutoPlayHandle(autoPlayStatus) {
        if (autoPlayStatus == 'on') {
            setIsAutoPlay(false)
            autoPlayStop()
        }
        else {
            setIsAutoPlay(true)
        }
    }

    // For Autoplay feature:
    const autoPlayStart = () => {
        setCurrentPage(currentPage => {
            if (currentPage === totalPageCountRef?.current) {
                return 1;
            } else {
                return currentPage + 1;
            }
        })
    }

    const autoPlayStop = () => {
        clearInterval(intervalId);
    }

    const onMosaicTypeChange = (e) => {
        setMosaicOption(e.target.value)

        let val = e.target.value

        if (val == 1) {
            try {
                mixpanel.track("Clicks on 2x2")
            } catch (error) {
                console.error(error)
            }
            setPageSize(4) //* For 2X2 per page cameras will be 4
            setIntervalTime(30)
        }
        else if (val == 2) {
            try {
                mixpanel.track("Clicks on 3x3")
            } catch (error) {
                console.error(error)
            }
            setPageSize(9)//* For 3X3 per page cameras will be 9
            setIntervalTime(30)
        }
        else if (val == 3) {
            try {
                mixpanel.track("Clicks on 4x4")
            } catch (error) {
                console.error(error)
            }
            setPageSize(16)//* For 4X4 per page cameras will be 16
            setIntervalTime(30)
        }
        else if (val == 4) {
            try {
                mixpanel.track("Clicks on 5x5")
            } catch (error) {
                console.error(error)
            }
            setPageSize(25)//* For 5X5 per page cameras will be 25
            setIntervalTime(30)
        }
        else if (val == 5) {
            try {
                mixpanel.track("Clicks on 6x6")
            } catch (error) {
                console.error(error)
            }
            setPageSize(36)//* For 6X6 per page cameras will be 36
            setIntervalTime(30)
        }
        else if (val == 6) {
            try {
                mixpanel.track("Clicks on 7x7")
            } catch (error) {
                console.error(error)
            }
            setPageSize(49)//* For 7X7 per page cameras will be 49
            setIntervalTime(30)
        }

        setCurrentPage(1)
    }

    const onMosaicTimeIntervalChange = (e) => {
        setIntervalTime(e.target.value);
        setCurrentPage(1)
    }

    const toggleFullscreen = () => {
        const doc = window.document;
        const docEl = doc.documentElement;

        const requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullscreen;
        const exitFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

        if (!isFullscreen) {
            if (requestFullScreen) {
                requestFullScreen.call(docEl);
            }
            setIsFullscreen(true);
        } else {
            if (exitFullScreen) {
                exitFullScreen.call(doc);
            }
            setIsFullscreen(false);
        }
    };

    const handleClearSelectedClick = () => {
        dispatch(filterActions.resetSavedFilter());
    }

    const handleSavedFilterItemSelectClick = async (e, item) => {
        dispatch(filterActions.setSavedFilterSelected(item));
        let filterData = parseSCSEntitiesToObect(item?.filterValues);
        if (isNotNullOrEmpty(filterData)) {
            let clientIds = filterData?.Client;
            let siteIds = filterData?.Site;
            let interfaceSubTypeIds = filterData?.Interfacesubtype;
            let interfaceIds = filterData?.Interface;
            let devicesIds = filterData?.Device
            //send true if applying from saved filter
            // dispatch(fetchDevicesList(clientIds ?? "", siteIds ?? "", interfaceSubTypeIds ?? "", interfaceIds ?? "", devicesIds ?? "", true));
            await getdeviceslistdata(clientIds, siteIds, interfaceSubTypeIds, interfaceIds, devicesIds) //device master list
        }

    }

    //#endregion functions end
    const totalNoOfCameras = cameraDeviceList != null ? cameraDeviceList.length : 0;

    //** currentTableData is used for the list to be shown on the selected page
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        let pageList = cameraDeviceList?.slice(firstPageIndex, lastPageIndex);
        setCamerasUI(pageList)
        return pageList
    }, [currentPage, cameraDeviceList, pageSize]);

    //#endregion

    //#region DDLSingleSelect props
    const DDLSingleSelectProps = {
        labelledBy: savedFilters?.selected?.filterName !== undefined ? `${SAVEDFILTER}: ${savedFilters?.selected?.filterName}` : SAVEDFILTER,
        emptyOptionLabel: "No Saved Filters",
        handleClearSelectedClick: handleClearSelectedClick,
        clearDisabled: !isNotNullOrEmpty(savedFilters?.selected),
        options: savedFilters?.options,
        valueRenderer: null,
        itemRenderer: (item) => (
            <>
                <div className="SavedFilterOption" onClick={(e) => handleSavedFilterItemSelectClick(e, item)}>
                    <span>{item?.filterName}</span>
                </div>
            </>
        )
    }
    //#endregion

    const toggleStretchVideo = () => {
        setStretchVideo(!stretchVideo)
        if (!stretchVideo) {
          SuccessToast("Videos are now stretch to the grid")
        } else {
          SuccessToast("Videos are now displayed in there original aspect ratio")
        }
      }

    return (
        <div className="main-mosaic">
            {isMenuVisible &&
                <div id="myNav" className="overlay">
                    <div className='inner-row'>
                        <div className="item-align">
                        </div>

                        <div className='MosaicGrid'>
                            <div className="mosaic-popup item-align mosaicSavedFilter">
                                <DDLSingleSelect {...DDLSingleSelectProps} />
                            </div>
                            <div className="mosaic-popup item-align mosaic-content">
                                <img src={MosaicIcon} alt="Mosaic icon" />
                                <span className="mosaic-popup-text">
                                    Mosaic
                                </span>
                                <select className="select-ext mosaic-select" defaultValue={mosaicOption} onChange={e => onMosaicTypeChange(e)} >
                                    <option id="4" value="1">2 x 2</option>
                                    <option id="9" value="2">3 x 3</option>
                                    <option id="16" value="3">4 x 4</option>
                                    <option id="25" value="4">5 x 5</option>
                                    <option id="36" value="5">6 x 6</option>
                                    <option id="49" value="6">7 x 7</option>
                                </select>
                            </div>
                            <div className="mosaic-popup item-align mosaic-content">
                                <span className="auto-play-text">
                                    Auto Play
                                </span>
                                {isAutoPlay ? <img src={ToggleIcon} alt="Mosaic icon" onClick={() => OnAutoPlayHandle('on')} />
                                    : <img src={AutoPlayOff} alt="Mosaic icon" onClick={() => OnAutoPlayHandle('off')} />}
                            </div>
                            <div className="mosaic-popup item-align mosaic-content">
                                <span className="auto-play-text">
                                    {`${camerasUI && JSON.stringify(camerasUI.length)} of ${totalNoOfCameras}`}
                                    {/* If data is present in the camerasUI then other condition will execute. */}
                                </span>
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={cameraDeviceList != null ? cameraDeviceList.length : 0}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                    pageName='mosaic'
                                />
                                <>
                                    <select className="select-ext mosaic-select" value={intervalTime} onChange={e => onMosaicTimeIntervalChange(e)}>
                                        {options.filter(option => {
                                            if (pageSize === 4) return true;
                                            if (pageSize === 9) return option.value >= 30;
                                            if (pageSize === 16) return option.value >= 30;
                                            if (pageSize === 25) return option.value >= 30;
                                            if (pageSize === 36) return option.value >= 30;
                                            if (pageSize === 49) return option.value >= 30;
                                            return false;
                                        }).map(option => (
                                            <option key={option.value} value={option.value} id="mosaic-timeInterval">{option.label}</option>
                                        ))}
                                    </select>
                                </>
                                <OverlayTrigger 
                                placement='bottom'
                                overlay={
                                  !stretchVideo ? (
                                    <Tooltip>Stretch Video</Tooltip>
                                  ) : (
                                    <Tooltip>Restore Video</Tooltip>
                                  )
                                }
                                >
                            <button
                              style={{background: "transparent"}}
                              onClick={toggleStretchVideo}
                            >
                              {!stretchVideo ? (
                                <div>
                                  <img
                                    style={{ padding: "2px", height: "24px" }}
                                    src={StretchVideo}
                                    alt="Full"
                                  />
                                </div>
                              ) : (
                                <div>
                                <img
                                  style={{ padding: "2px", height: "24px" }}
                                  src={ExitStretchVideo}
                                  alt="Exit"
                                />
                                </div>
                              )}
          </button>
          </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Full Screen
                                        </Tooltip>
                                    }
                                >
                                    <div className=" fullscreen-icon col-lg-1 pl-0 ml-3">
                                        <img src={FullScreen} alt="fullscreen icon" onClick={toggleFullscreen} />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={isMenuVisible ? 'arrow-icon' : 'arrow-icon arrow-icon-collapse'}>
                <Row>
                    <Col><img src={ArrowIcon} alt='Arrow' onClick={() => onArrowIconClick()}></img></Col>
                </Row>
            </div>

            <div className="mosaic-row">
                <>
                    {
                        mosaicOption == 1 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent2x2"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }

                    {
                        mosaicOption == 2 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent3x3"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }

                    {
                        mosaicOption == 3 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent4x4"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }

                    {
                        mosaicOption == 4 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent5x5"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }
                    {
                        mosaicOption == 5 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent6x6"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }
                    {
                        mosaicOption == 6 &&
                        <Matrix
                            key={oneKey}
                            cameras={camerasUI}
                            gridType="parent7x7"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                            stretchVideo={stretchVideo}
                        />
                    }
                </>
            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}